<template lang="pug">
  v-app
    .loading-indicator(v-if="loading")
      i.fas.fa-spin.fa-spinner
    .quiz-top(v-if="!quizStart")
      img(
        class="quiz-indicator"
        :src="require(`@/assets/images/quiz/quiz_level_3_${$store.getters.currentLocale}.png`)"
        @click="startQuiz"
      )
    .quiz-top(v-if="quizStart")
      .quiz-window
        .quiz-container(v-if="quizStep == 0")
          .h-100(v-html="$t('quiz.level.three.video')")
        .quiz-container(v-if="quizStep == 1")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 3
          .quiz-body
            .quiz-question {{ $t("quiz.level.three.questions[0].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.three.questions[0].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.three.questions[0].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.three.questions[0].options[2]') }}
        .quiz-container(v-if="quizStep == 2")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 3
          .quiz-body
            .quiz-question {{ $t("quiz.level.three.questions[1].question") }}
            .quiz-choices(:class="mobileView ? 'flex-column' : 'flex-row'")
              .quiz-answer(
                :class="{'selected': choice[quizStep].includes('a'), 'correct': score[quizStep] == 1,'wrong': score[quizStep] == 0}"
                @click="selectMultipleAnswer(quizStep, 'a')"
              )
                .label a. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level3/l3_2_1.png")
                  b {{ $t('quiz.level.three.questions[1].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep].includes('b'), 'correct': score[quizStep] == 1,'wrong': score[quizStep] == 0}"
                @click="selectMultipleAnswer(quizStep, 'b')"
              )
                .label b. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level3/l3_2_2.png")
                  b {{ $t('quiz.level.three.questions[1].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep].includes('c'), 'correct': score[quizStep] == 1,'wrong': score[quizStep] == 0}"
                @click="selectMultipleAnswer(quizStep, 'c')"
              )
                .label c. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level3/l3_2_3.png")
                  b {{ $t('quiz.level.three.questions[1].options[2]') }}
        .quiz-container(v-if="quizStep == 3")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 3
          .quiz-body
            .quiz-question {{ $t("quiz.level.three.questions[2].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.three.questions[2].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.three.questions[2].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.three.questions[2].options[2]') }}
        .quiz-container(v-if="quizStep == 4")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 3
          .quiz-body
            .quiz-question {{ $t("quiz.level.three.questions[3].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.three.questions[3].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.three.questions[3].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.three.questions[3].options[2]') }}
        .quiz-container(v-if="quizStep == 5")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 3
          .quiz-body
            .quiz-question {{ $t("quiz.level.three.questions[4].question") }}
            .quiz-choices(:class="mobileView ? 'flex-column' : 'flex-row'")
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .label a. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level3/l3_5_1.png")
                  b {{ $t('quiz.level.three.questions[4].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .label b. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level3/l3_5_2.png")
                  b {{ $t('quiz.level.three.questions[4].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .label c. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level3/l3_5_3.png")
                  b {{ $t('quiz.level.three.questions[3].options[2]') }}
      v-btn(
        v-if="quizStep == 0"
        class="continue-button"
        @click="continueQuiz"
        color="primary"
      ) 
        b {{ $t('quiz.continue') }}
      v-btn(
        v-else-if="quizStep == 5 && score[quizStep] != null"
        class="continue-button"
        @click="saveQuiz"
        color="primary"
      ) 
        b {{ $t('quiz.level.three.complete') }}
      v-btn(
        v-else-if="score[quizStep] == null"
        class="continue-button"
        @click="submitAnswer(quizStep)"
        color="primary"
      ) 
        b {{ $t('quiz.answer') }}
      v-btn(
        v-else-if="score[quizStep] != null"
        class="continue-button"
        @click="nextQuestion()"
        color="primary"
      ) 
        b {{ $t('quiz.next_question') }}
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";

export default {
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      quizStart: false,
      quizStep: 0,
      timeout: null,
      answer: {
        1: "b",
        2: ["a", "b", "c"],
        3: "c",
        4: "c",
        5: "b"
      },
      choice: {
        1: "",
        2: [],
        3: "",
        4: "",
        5: ""
      },
      score: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null
      },
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  mounted() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.quizStart = true;
    }, 2000);
  },
  methods: {
    startQuiz() {
      if (!this.quizStart) this.quizStart = true;
    },
    continueQuiz() {
      this.quizStep = 1;
    },
    selectAnswer(step, answer) {
      if (this.score[step] != null) return;
      else this.choice[step] = answer;
    },
    selectMultipleAnswer(step, answer) {
      var index = this.choice[step].indexOf(answer);
      if (index > -1) this.choice[step].splice(index, 1);
      else this.choice[step].push(answer);
    },
    submitAnswer(step) {
      if (this.choice[step] == "") return;
      if (step == 2) {
        if (
          this.choice[step].length === this.answer[step].length &&
          this.choice[step].every(element => {
            return this.answer[step].includes(element);
          })
        )
          this.score[step] = 1;
        else this.score[step] = 0;
      } else if (this.choice[step] == this.answer[step]) this.score[step] = 1;
      else this.score[step] = 0;
    },
    nextQuestion() {
      this.quizStep += 1;
    },
    saveQuiz() {
      let url = config.saveQuizUrl();

      var json = {
        userId: this.$store.getters.userId,
        level: 3,
        isDone: true,
        rawData: this.choice,
        score: Object.values(this.score).reduce((a, b) => {
          return a + b;
        }, 0),
        locale: this.$store.getters.currentLocale
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.$store.commit("customer/completeQuiz", { level: 3 });
                this.$router.push({
                  name: "QuizDone"
                });
              } else {
                this.$swal(
                  this.$t("error.save_quiz"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.save_quiz"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.save_quiz"),
            response.body.error.message,
            "error"
          );
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.v-application
  min-height: 90vh !important
  overflow: hidden !important
</style>
